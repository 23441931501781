import React from "react";
import PageHeader from "../component/PageHeader";

const Home = () => {
  return (
    <div className="home">
      <PageHeader title="Home" />
    </div>
  );
};

export default Home;
