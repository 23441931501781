import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../api";
import { withRouter, useHistory } from "react-router-dom";

const UpdatePrice = () => {
  let history = useHistory();
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [formData, setFormData] = useState({
  });
  const [stateUpdate, setStateUpdate] = useState(false);
  const [generatedVariation, setGeneratedVariation] = useState();

  const fieldsOnChangeHandler = (e, x) => {
    const newData = formData;
    const value = e.target.value;
    const name = e.target.name;
    if (x === true) {
      newData.custom_attributes[name] = value;
    } else {
      newData[name] = value;
    }
    setStateUpdate(!stateUpdate);
    setFormData(newData);
  };

  const createHandler = async () => {
    var arrOfObj = {
      baseInfo: formData,
      sizeVariations: generatedVariation,
    };
    const res = await axios.post(`${URL}/update-price`, {
      data: arrOfObj,
    });
    if (res.status == 201) {
      setStatus(false);
      setMessage("The sku you entered doesn't exist in the system. Could you try adding a hyphen (-) to the end? If the issue persists check that the item exists.");
    } else {
      history.push("/add-listing/success");
    }
  };

  return (
    <div className="listing-form">
      <h2>Update Price</h2>
      {message && (
            <div
              className={`btn ${
                status ? "btn-success" : "btn-danger"
              } d-block text-left`}
            >
              {message}
            </div>
          )}
      <div className="listing-create-form">
      <div class="shared-fields">
      <div class="row">
      <div class="col-md-2 mb-3">
        <label className="mr-3" htmlFor="parentSku">
              Parent Sku
        </label>
        <input
            name="parentSku"
            type="text"
            placeholder="Parent Sku"
            className="form-control"
            onChange={(e) => fieldsOnChangeHandler(e)}
        />
      </div>
      <div class="col-md-2 mb-3">
      <label className="mr-3" htmlFor="price">
              Price
        </label>
        <input
            name="price"
            type="number"
            placeholder="Price"
            className="form-control"
            onChange={(e) => fieldsOnChangeHandler(e)}
        />
      </div>
      <div class="col-md-2 mb-3">
      <label className="mr-3" htmlFor="msrp">
              MSRP
        </label>
        <input
            name="msrp"
            type="number"
            placeholder="MSRP"
            className="form-control"
            onChange={(e) => fieldsOnChangeHandler(e)}
        />
      </div>
      </div>
      </div>
        <button
          onClick={createHandler}
          className="btn btn-primary btn-form w-50 ml-2"
        >
          Submit Product
        </button>
      </div>
    </div>
  );
};

export default UpdatePrice;
