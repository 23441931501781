import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../api";
import CoatInput from "../component/CoatInput";
import SharedInput from "../component/SharedInput";
import ShirtInput from "../component/ShirtInput";
import ShoeInput from "../component/ShoeInput";
import SuitInput from "../component/SuitInput";
import BlazerInput from "../component/BlazerInput";
import PantsInput from "../component/PantsInput";
import BoysInput from "../component/BoysInput";
import AccessoriesInput from "../component/AccessoriesInput";
import VariantGenerator from "../component/VariantGenerator";
import { withRouter, useHistory } from "react-router-dom";

const AddListing = () => {
  let history = useHistory();
  const [warehouse, setWarehouse] = useState([]);
  const [images, setImages] = useState(null);
  const [formData, setFormData] = useState({
    custom_attributes: {},
    category_name: "",
  });
  const [stateUpdate, setStateUpdate] = useState(false);
  const [generatedVariation, setGeneratedVariation] = useState();
  const [variationKey, setVariationKey] = useState();
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    fetch(`${URL}/warehouse`)
      .then((data) => data.json())
      .then((res) => {
        var data = res;
        const found = data.filter(
          (el) =>
            el.uuid === "0b941d1b-7845-4ec5-a96f-d23ef82d91c5" ||
            el.uuid === "5505ef96-78c3-4a3e-9be3-17554e962600" ||
            el.uuid === "8d49b2d7-e61e-4d3b-b01f-6040a8d35562"
        );
        // console.log(found);
        setWarehouse(found);
      })
      .catch((err) => console.log(err));
  }, []);

  const imageHandler = async () => {
    var formData = new FormData();
    for (const key of Object.keys(images)) {
      formData.append("imgCollection", images[key]);
    }

    const result = await axios.post(`${URL}/add-listing`, formData);
    setImageUrls(result.data);
    alert("Images Uploaded");
  };

  const fieldsOnChangeHandler = (e, x) => {
    const newData = formData;
    const value = e.target.value;
    const name = e.target.name;
    console.log(x);
    console.log(name);
    console.log(value);
    if (x === true) {
      newData.custom_attributes[name] = value;
    } else {
      newData[name] = value;
    }
    setStateUpdate(!stateUpdate);
    setFormData(newData);
    console.log(formData);
  };

  const createHandler = async () => {
    var arrOfObj = {
      baseInfo: formData,
      sizeVariations: generatedVariation,
      images: imageUrls,
    };
    const res = await axios.post(`${URL}/create-listings`, {
      data: arrOfObj,
    });
    if (res.status === 200) {
      history.push("/add-listing/success");
    }
  };

  const setGenaratedVariant = (variants) => {
    if (!formData.parent_sku) return alert("Please Enter Parent SKU");

    console.log(variants);
    const tempArray = [];
    let fields;
    if (variants) {
      fields = Object.keys(variants[0]);
      setVariationKey(fields);
    }

    variants.map((el) => {
      const t = [];
      fields.map((f) => {
        t.push(el[f]);
      });

      warehouse.map((w) => {
        el[w.uuid] = 0;
        return (el["sku"] = `${formData.parent_sku}-${t.join("-")}`);
      });
      return tempArray.push(el);
    });
    setGeneratedVariation(tempArray);
  };

  const variationQuantityHandler = (index, name, value) => {
    const arr = [...generatedVariation];
    arr[index][name] = value;
    console.log(arr);
  };

  return (
    <div className="listing-form">
      <h2>Create New Listing</h2>
      <div className="listing-create-form">
        <div className="shared-fields">
          <div className="row">
            <SharedInput
              fieldsOnChangeHandler={(e) => fieldsOnChangeHandler(e)}
            />

            <div className="col-md-12 mb-3">
              <label className="d-block">Upload Images</label>
              <input
                className="shadow-sm w-100 p-2 bg-white "
                type="file"
                name="images"
                multiple
                onChange={(e) => setImages(e.target.files)}
              />
              <button
                onClick={imageHandler}
                className="btn btn-primary btn-form w-50 ml-2"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
        <div>
          {formData.category_name === "Suits" && (
            <SuitInput
              fieldsOnChangeHandler={(e, i) => fieldsOnChangeHandler(e, i)}
            />
          )}
          {formData.category_name === "Blazers" && (
            <BlazerInput
              fieldsOnChangeHandler={(e, i) => fieldsOnChangeHandler(e, i)}
            />
          )}  
          {formData.category_name === "Shirts" && (
            <ShirtInput
              fieldsOnChangeHandler={(e, i) => fieldsOnChangeHandler(e, i)}
            />
          )}
          {formData.category_name === "Outerwear" && (
            <CoatInput
              fieldsOnChangeHandler={(e, i) => fieldsOnChangeHandler(e, i)}
            />
          )}
          {formData.category_name === "Shoes" && (
            <ShoeInput
              fieldsOnChangeHandler={(e, i) => fieldsOnChangeHandler(e, i)}
            />
          )}
          {formData.category_name === "Accessories" && (
            <AccessoriesInput
              fieldsOnChangeHandler={(e, i) => fieldsOnChangeHandler(e, i)}
            />
          )}
          {formData.category_name === "Pants" && (
            <PantsInput
              fieldsOnChangeHandler={(e, i) => fieldsOnChangeHandler(e, i)}
            />
          )}
          {formData.category_name === "Boys" && (
            <BoysInput
              fieldsOnChangeHandler={(e, i) => fieldsOnChangeHandler(e, i)}
            />
          )}

          <VariantGenerator
            setGenaratedVariant={(variants) => setGenaratedVariant(variants)}
          />
        </div>

        <div className="listing-form">
          <table className="table bg-white">
            <thead>
              <tr>
                <th scope="col">SKU</th>
                {variationKey &&
                  variationKey.map((el) => (
                    <th key={el} scope="col">
                      {el}
                    </th>
                  ))}

                <th scope="col">Store Front Quantity</th>
                <th scope="col">Northend Warehouse Quantity</th>
                <th scope="col">Back Storeroom Quantity</th>
              </tr>
            </thead>
            <tbody>
              {generatedVariation &&
                generatedVariation.map((el, i) => (
                  <tr key={el.sku}>
                    <th scope="row">
                      <input
                        type="text"
                        name={el.sku}
                        value={el.sku}
                        placeholder="Sku"
                        className="form-control"
                        readOnly={true}
                      />
                    </th>
                    {variationKey.map((key) => (
                      <td key={key}>
                        <input
                          className="form-control"
                          type="text"
                          name={el[key]}
                          value={el[key]}
                          readOnly={true}
                        />
                      </td>
                    ))}
                    <td>
                      <input
                        type="number"
                        name="8d49b2d7-e61e-4d3b-b01f-6040a8d35562"
                        placeholder="Store Front Quantity"
                        className="form-control"
                        onChange={(e) =>
                          variationQuantityHandler(
                            i,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="0b941d1b-7845-4ec5-a96f-d23ef82d91c5"
                        placeholder="Northend Warehouse Quantity"
                        className="form-control"
                        onChange={(e) =>
                          variationQuantityHandler(
                            i,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="5505ef96-78c3-4a3e-9be3-17554e962600"
                        placeholder="Back Storeroom Quantity"
                        className="form-control"
                        onChange={(e) =>
                          variationQuantityHandler(
                            i,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <button
          onClick={createHandler}
          className="btn btn-primary btn-form w-50 ml-2"
        >
          Submit Product
        </button>
      </div>
    </div>
  );
};

export default AddListing;
