import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "../api";
import { withRouter, useHistory } from "react-router-dom";
import sad from "../assets/sadtone.mp3";
import happy from "../assets/success.mp3";

const AddSize = () => {
  let history = useHistory();
  const [warehouse, setWarehouse] = useState([]);
  const [images, setImages] = useState(null);
  const [formData, setFormData] = useState({
  });
  const [stateUpdate, setStateUpdate] = useState(false);
  const [generatedVariation, setGeneratedVariation] = useState();
  const [variationKey, setVariationKey] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetch(`${URL}/warehouse`)
      .then((data) => data.json())
      .then((res) => {
        var data = res;
        const found = data.filter(
          (el) =>
            el.uuid === "0b941d1b-7845-4ec5-a96f-d23ef82d91c5" ||
            el.uuid === "5505ef96-78c3-4a3e-9be3-17554e962600" ||
            el.uuid === "8d49b2d7-e61e-4d3b-b01f-6040a8d35562"
        );
        // console.log(found);
        setWarehouse(found);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    clearMessage();
  }, [message, success]);

  const clearMessage = () => {
    setTimeout(() => {
      setMessage("");
      setSuccess("");
    }, 3000);
  };

  const fieldsOnChangeHandler = (e, x) => {
    const newData = formData;
    const value = e.target.value;
    const name = e.target.name;
    if (x === true) {
      newData.custom_attributes[name] = value;
    } else {
      newData[name] = value;
    }
    setStateUpdate(!stateUpdate);
    setFormData(newData);
    console.log(formData);
  };

  const createHandler = async () => {
    setSuccess("");
    setMessage("");
    const audio = new Audio(sad);
    const audios = new Audio(happy);
    audio.pause();
    audios.pause();
    var arrOfObj = {
      baseInfo: formData,
      sizeVariations: generatedVariation,
    };
    const res = await axios.post(`${URL}/add-size`, {
      data: arrOfObj,
    });
    if (res.status === 200) {
      setSuccess("Item Being Created! Please check Sellbrite to see if the size needs to be added in.");
      audios.play();
    } else {
      setMessage("Item either has strange options or doesn't have a parent");
      audio.play();
    }
  };

  const setGenaratedVariant = (variants) => {
    if (!formData.parent_sku) return alert("Please Enter Parent SKU");

    console.log(variants);
    const tempArray = [];
    let fields;
    if (variants) {
      fields = Object.keys(variants[0]);
      setVariationKey(fields);
    }

    variants.map((el) => {
      const t = [];
      fields.map((f) => {
        t.push(el[f]);
      });

      warehouse.map((w) => {
        el[w.uuid] = 0;
        return (el["sku"] = `${formData.parent_sku}-${t.join("-")}`);
      });
      return tempArray.push(el);
    });
    setGeneratedVariation(tempArray);
  };

  const variationQuantityHandler = (index, name, value) => {
    const arr = [...generatedVariation];
    arr[index][name] = value;
    console.log(arr);
  };

  return (
    <div className="listing-form">
        {message && <div className="alert alert-danger">{message}</div>}
        {success && <div className="alert alert-success">{success}</div>}
      <h2>Add Size To A Listing</h2>
      <div className="listing-create-form">
      <div class="shared-fields">
      <div class="row">
      <div class="col-md-2 mb-3">
        <label className="mr-3" htmlFor="parentSku">
              Parent Sku
        </label>
        <input
            name="parentSku"
            type="text"
            placeholder="Parent Sku"
            className="form-control"
            onChange={(e) => fieldsOnChangeHandler(e)}
        />
      </div>
      <div class="col-md-2 mb-3">
      <label className="mr-3" htmlFor="sku">
              Child Sku
        </label>
        <input
            name="sku"
            type="text"
            placeholder="Sku"
            className="form-control"
            onChange={(e) => fieldsOnChangeHandler(e)}
        />
      </div>
      <div class="col-md-2 mb-3">
      <label className="mr-3" htmlFor="size">
              Size
        </label>
        <input
            name="size"
            type="text"
            placeholder="Size"
            className="form-control"
            onChange={(e) => fieldsOnChangeHandler(e)}
        />
      </div>
      </div>
      <div class="row">
        <div class="col-md-2 mb-3">
        <label className="mr-3" htmlFor="storefront">
                Store Front
            </label>
            <input
                name="storefront"
                type="number"
                className="form-control"
                onChange={(e) => fieldsOnChangeHandler(e)}
            />
        </div>
        <div class="col-md-2 mb-3">
        <label className="mr-3" htmlFor="northend">
                Northend
            </label>
            <input
                name="northend"
                type="number"
                className="form-control"
                onChange={(e) => fieldsOnChangeHandler(e)}
            />
        </div>
        <div class="col-md-2 mb-3">
        <label className="mr-3" htmlFor="backwarehouse">
                Back Warehouse
            </label>
            <input
                name="backwarehouse"
                type="number"
                className="form-control"
                onChange={(e) => fieldsOnChangeHandler(e)}
            />
        </div>
      </div>
      </div>
        <button
          onClick={createHandler}
          className="btn btn-primary btn-form w-50 ml-2"
        >
          Submit Product
        </button>
      </div>
    </div>
  );
};

export default AddSize;
