import React, { useState } from 'react';
import PasswordInput from './component/PasswordInput'; // Import the PasswordInput component
import Sidebar from './component/Sidebar';
import Inventory from './page/Inventory';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Scanner from './page/Scanner';
import AddInventory from './page/AddInventory';
import AdditionalInventory from './page/AdditionalInventory';
import CreateReport from './page/CreateReport';
import RemoveInventory from './page/RemoveInventory';
import SendToClearance from './page/SendToClearance';
import Transfer from './page/Transfer';
import AddSize from './page/AddSize';
import AddListing from './page/AddListing';
import SuccessPage from './page/SuccessPage';
import UpdatePrice from './page/UpdatePrice';
import SalesReport from './page/SalesReport';
import BwSfTransfer from './page/BWToStore';
import Home from './page/Home';
import StockMovements from './page/StockMovements';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  // Handle authentication when the user submits the password
  const handlePasswordSubmit = (password) => {
    // Verify the password (you can compare it to a predefined password)
    if (password === '12750North613') {
      setAuthenticated(true);
    } else {
      alert('Invalid password');
    }
  };

  return (
    <div className="App">
      {authenticated ? (
        // If authenticated, render your application content
        <Router>
          <div className="main_window d-flex">
            <div className="main_left col-2">
              <Sidebar />
            </div>
            <div className="main_right offset-2">
              <Switch>
                <Route exact path="/add-listing">
                  <AddListing />
                </Route>
                <Route exact path="/transfer">
                  <Transfer />
                </Route>
                <Route exact path="/additional-inventory">
                  <AdditionalInventory />
                </Route>
                <Route exact path="/remove-inventory">
                  <RemoveInventory />
                </Route>
                <Route exact path="/create-report">
                  <CreateReport />
                </Route>
                <Route exact path="/send-to-clearance">
                  <SendToClearance />
                </Route>
                <Route exact path="/inventory">
                  <Inventory />
                </Route>
                <Route exact path="/add-inventory">
                  <AddInventory />
                </Route>
                <Route exact path="/scanner">
                  <Scanner />
                </Route>
                <Route exact path="/add-size">
                  <AddSize />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/add-listing/success">
                  <SuccessPage />
                </Route>
                <Route exact path="/update-price">
                  <UpdatePrice />
                </Route>
                <Route exact path="/stock-movements">
                  <StockMovements />
                </Route>
                <Route exact path="/sales-report">
                  <SalesReport />
                </Route>
                <Route exact path="/bw-sf-transfer">
                  <BwSfTransfer />
                </Route>
              </Switch>
            </div>
          </div>
        </Router>
      ) : (
        // If not authenticated, display the PasswordInput component
        <PasswordInput onPasswordSubmit={handlePasswordSubmit} />
      )}
    </div>
  );
}

export default App;