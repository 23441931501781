import React, { useEffect, useState, useRef } from "react";
import URL from "../api";
import axios from "axios";
import sad from "../assets/sadtone.mp3";
import Loading from "../utils/Spinner";

const createHandler = async () => {
    /** var arrOfObj = {
      baseInfo: formData,
      sizeVariations: generatedVariation,
      images: images,
    }; */
    const res = await axios.post(`${URL}/create-listings`, {
      //data: arrOfObj,
    });
    console.log(res);
  };

const CreateReport = () => {
    return (
        <div>
            <h2>Create Report</h2>
            <div className="listing-create-form">
            <div className="row">
                <div className="col-md-4 mb-3">
                    <label className="mr-3" htmlFor="brand">
                        Brand
                    </label>
                    <input
                        name="brand"
                        type="text"
                        placeholder="Brand"
                        className="form-control"
                    />
                </div>
                <div className="col-md-4 mb-3">
                    <label className="mr-3" htmlFor="category">
                        Category
                    </label>
                    <input
                        name="category"
                        type="text"
                        placeholder="Category"
                        className="form-control"
                    />
                </div>
                <div className="col-md-4 mb-3">
                    <label className="mr-3" htmlFor="fit">
                        Fit
                    </label>
                    <input
                        name="fit"
                        type="text"
                        placeholder="Fit"
                        className="form-control"
                    />
                </div>
            </div>
            <button
                onClick={createHandler}
                className="btn btn-primary btn-form w-100 ml-2"
            >
            Generate Report
            </button>
            </div>
        </div>
    );
};

export default CreateReport;