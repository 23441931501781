import React, { useState } from "react";

const VariantGenerator = ({ setGenaratedVariant }) => {
  const [variant, setvariant] = useState(false);
  const [variantName, setVariantName] = useState("");
  const [totalVariant, setTotalVariant] = useState([]);
  const [multiVariant, setMultiVariant] = useState([]);

  const variantNameHandler = (e) => {
    if (variantName === "Size" || variantName === "Color" || variantName === "Neck Size" || variantName === "Sleeve Length") {
      e.preventDefault();
      const old = [...totalVariant];
      const obj = { vName: variantName, variantValue: [] };
      old.push(obj);
      setTotalVariant(old);
      setVariantName("");
    } else {
      e.preventDefault();
      alert("You cannot enter a variation other than Size or Color. If you are trying to enter a specific Size or Color scroll down and enter it in the line of the variations");
      const old2 = [...totalVariant];
      setTotalVariant(old2);
      setVariantName("");
    }
  };

  const removeVariant = (index) => {
    const arr = [...totalVariant];
    arr.splice(index, 1);
    setTotalVariant(arr);
  };

  const variantValueHandler = (index, e) => {
    e.preventDefault();
    const arr = [...totalVariant];
    arr[index].variantValue.push(multiVariant[index]);
    const oldMulti = [...multiVariant];
    oldMulti[index] = "";
    setMultiVariant(oldMulti);
    setTotalVariant(arr);
  };

  const multiValueHandler = (value, name, index) => {
    const arr = [...multiVariant];
    arr[index] = value;
    setMultiVariant(arr);
  };

  const removeVariantValue = (valueIndex, varIndex) => {
    const arr = [...totalVariant];
    arr[varIndex].variantValue.splice(valueIndex, 1);
    setTotalVariant(arr);
  };

  const genarateVariantionHandler = () => {
    const output = [];

    function f_arrange(arr, idx, item) {
      if (idx < arr.length) {
        arr[idx].variantValue.forEach((v) => {
          let obj = Object.assign({}, item, { [arr[idx].vName]: v });
          f_arrange(arr, idx + 1, obj);
        });
      } else output.push(item);
    }

    f_arrange(totalVariant, 0, {});

    setGenaratedVariant(output);
  };

  return (
    <>
      <div className="form-check my-3">
        <input
          className="form-check-input"
          type="checkbox"
          value={variant}
          id="variantCheck"
          onChange={(e) => setvariant(e.target.checked)}
        />
        <label className="form-check-label">
          Check if you want to Add Variant
        </label>
      </div>
      {variant && (
        <div className="mb-3">
          <form onSubmit={variantNameHandler}>
            <label className="form-label">Type Variant Name</label>
            <input
              onChange={(e) => setVariantName(e.target.value)}
              value={variantName}
              type="text"
              placeholder="Type Variant Name"
              className="form-control"
            />
          </form>
        </div>
      )}

      {variant && (
        <>
          {totalVariant.length > 0 && (
            <>
              <div className="bg-white m-3 p-3 rounded">
                {totalVariant.map((el, index) => (
                  <div className="row align-items-center my-3" key={el.vName}>
                    <div className="col-1">
                      <h5 className="text-capitalize mr-2 m-0">{el.vName}</h5>
                    </div>
                    <form
                      onSubmit={(e) => variantValueHandler(index, e)}
                      className="col-10"
                    >
                      <div className="bg-light p-2 d-flex align-items-center">
                        {totalVariant[index] &&
                          totalVariant[index].variantValue.map((el, i) => (
                            <span
                              key={i}
                              onClick={() => removeVariantValue(i, index)}
                              className="small bg-white p-1 mx-1 cursor-pointer"
                            >
                              {el}
                            </span>
                          ))}
                        <input
                          style={{ outline: "none" }}
                          type="text"
                          className="ml-3 border-0 bg-light outline-0 w-100"
                          placeholder="Enter Value "
                          name={el.vName}
                          onChange={(e) =>
                            multiValueHandler(e.target.value, el.vName, index)
                          }
                          value={multiVariant[index]}
                        />
                      </div>
                    </form>
                    <div className="col-1">
                      <i
                        onClick={() => removeVariant(index)}
                        className="fas fa-window-close btn btn-danger"
                      ></i>
                    </div>
                  </div>
                ))}

                <div className="text-left">
                  <button
                    onClick={genarateVariantionHandler}
                    className="btn btn-warning"
                  >
                    Genarate Variant
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default VariantGenerator;
