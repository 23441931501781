import React from "react";
import inputFields from "../assets/listingFields";

const SharedInput = ({ fieldsOnChangeHandler }) => {
  const renderSharedInputs = inputFields.sharedInputs.map((el) => {
    if (el.select === false) {
      if (el.type === "textarea") {
        return (
          <div
            className={
              el.name === "description"
                ? "col-md-10 mb-3"
                : el.name.includes("feature")
                ? "col-md-4 mb-3"
                : "col-md-2 mb-3"
            }
            key={el.name}
          >
            <label className="mr-3" htmlFor={el.name}>
              {el.label}
            </label>
            <textarea
              className="text-area form-control"
              name={el.name}
              onChange={(e) => fieldsOnChangeHandler(e)}
              rows={3}
              cols={75}
              placeholder={el.label}
              required={el.required}
            />
          </div>
        );
      } else {
        return (
          <div
            className={
              el.name === "name"
                ? "col-md-10 mb-3"
                : el.name.includes("feature")
                ? "col-md-6 mb-3"
                : "col-md-2 mb-3"
            }
            key={el.name}
          >
            <label className="mr-3" htmlFor={el.name}>
              {el.label}
            </label>
            <input
              onChange={(e) => fieldsOnChangeHandler(e)}
              name={el.name}
              type={el.type}
              placeholder={el.label}
              required={el.required}
              maxLength={el.maxLength}
              className="form-control"
            />
          </div>
        );
      }
    } else {
      return (
        <div
          key={el.name}
          className={
            el.name === "category_name" ? "col-md-2 mb-3" : "col-md-2 mb-3"
          }
        >
          <label htmlFor={el.name}>{el.label}</label>
          <select
            onChange={(e) => fieldsOnChangeHandler(e)}
            name={el.name}
            placeholder={el.label}
            required={el.required}
            className="form-control"
          >
            <option defaultValue={el.label} disabled selected>
              Select {el.label}
            </option>
            {el.option.map((op) => (
              <option value={op} key={op}>
                {op}
              </option>
            ))}
          </select>
        </div>
      );
    }
  });

  return <>{renderSharedInputs}</>;
};

export default SharedInput;
