import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import URL from "../api";
import download from "downloadjs";
import sad from "../assets/sadtone.mp3";
import happy from "../assets/success.mp3";
import PageHeader from "../component/PageHeader";

const Scanner = ({ items, warehouse_uid }) => {
  const [skuKey, setSkuKey] = useState("");
  const [newItem, setNewItems] = useState(null);
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [notFound, setNotFound] = useState([]);
  const [notInBinLocation, setNotInBinLocation] = useState([]);  // Tracks items scanned in wrong bin
  const [inUse, setInUse] = useState(true);
  const [binLocation, setBinLocation] = useState("");
  const [pressed, setPressed] = useState(false);
  const inputRef = useRef();
  
  useEffect(() => {
    if (inUse) {
      inputRef.current.focus();
    }
  });
  useEffect(() => {
    const oldarr = items.map((i) => ({ ...i }));
    setNewItems(oldarr);
    console.log("Initial newItem array:", oldarr); // Debugging line
  }, [items]);

  const scanHandler = async (e) => {
    setInUse(false);
    const audio = new Audio(sad);
    const audios = new Audio(happy);
    e.preventDefault();
    var isSku = newItem.find((el) => el.sku.toUpperCase() === skuKey.toUpperCase());
    var isSku2 = newItem.find((el) => el.barcode.toUpperCase() === skuKey.toUpperCase());
    var isSku3 = newItem.find((el) => el.sku.toUpperCase() === skuKey.toUpperCase() + "-");
    if (!isSku && isSku2) {
      isSku = true;
    } else if (!isSku && isSku3) {
      isSku = true;
    }

    if (!isSku) {
      setMessage(`${skuKey} Not found in the bin location, perhaps this belongs somewhere else`);
      audio.play();
      setStatus(false);
      const old = notFound;
      old.push({ sku: skuKey, bin_location: "Not Found in Bin Location" });
      setNotInBinLocation(old);
      setNotFound(old);
      clearMessage();
      var result = await axios.post(`${URL}/single-inventory`, {
        sku: skuKey,
        warehouseUid: warehouse_uid,
      });
    } else {
      for (const it of newItem) {
        if (it.sku.toUpperCase() === skuKey.toUpperCase() || it.barcode.toUpperCase() === skuKey.toUpperCase() || it.sku.toUpperCase() === skuKey.toUpperCase() + "-") {
          if (it.on_hand < 1) {
            var results = await axios.post(`${URL}/single-inventory`, {
              sku: it.sku.toUpperCase(),
              warehouseUid: warehouse_uid,
            });
          }
        }
      }
      setStatus(true);
      audios.play();
      setMessage(`Found and Scanned`);
      clearMessage();
    }
    newItem.map((item) => {
      if (item.sku.toUpperCase() === skuKey.toUpperCase() || item.barcode.toUpperCase() === skuKey.toUpperCase() || item.sku.toUpperCase() === skuKey.toUpperCase() + "-") {
        item.on_hand += 1;
      }
      return item;
    });
    setSkuKey("");
  };

  const clearMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  const csvHandler = () => {
    if (!newItem || newItem.length === 0) {
      alert("No data available for CSV export");
      return;
    }

    // Iterate directly through newItem to get expected and actual quantities.
    const differenceArray = newItem.map((item) => {
      return {
        sku: item.sku,
        expected: item.expected || 0, // Use expected from newItem
        actual: item.on_hand || 0, // Use actual from newItem (scanned quantity)
      };
    });

    // Prepare CSV for inventory snapshot (all items in new inventory, without product name).
    const inventorySnapshotArray = newItem.map((item) => {
      return {
        sku: item.sku,
        bin_location: item.bin_location,
        on_hand: item.on_hand || 0,
      };
    });

    // Prepare CSV for items that weren't in the right location.
    const notInBinLocationArray = notInBinLocation.map((item) => {
      return {
        sku: item.sku,
        product_name: item.product_name,
        expected_bin: item.expected_bin_location || "",
        scanned_bin: item.scanned_bin_location || "",
      };
    });

    // Convert arrays to CSV format.
    const convertToCSV = (arr) => {
      if (arr.length === 0) return "";
      const csvRows = [];
      const headers = Object.keys(arr[0]);
      csvRows.push(headers.join(","));
      for (const row of arr) {
        const values = headers.map((header) => row[header] === undefined ? "0" : row[header]);
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    };

    // Generate and download CSV files.
    const differenceCSV = convertToCSV(differenceArray);
    download(differenceCSV, "inventory-differences.csv", "text/csv");

    const inventorySnapshotCSV = convertToCSV(inventorySnapshotArray);
    download(inventorySnapshotCSV, "inventory-snapshot.csv", "text/csv");

    const binLocationCSV = convertToCSV(notInBinLocationArray);
    download(binLocationCSV, "incorrect-bin-locations.csv", "text/csv");
  };

  const resetHandler = () => {
    const oldarr = items.map((i) => ({ ...i, on_hand: 0 }));
    setNewItems(oldarr);
  };

  const increaseHandler = (i) => {
    setNewItems((prevNewItems) =>
      prevNewItems.map((item) =>
        item.sku === i.sku ? { ...item, on_hand: item.on_hand + 1 } : item
      )
    );
    setMessage(`${i.sku} Updated`);
    clearMessage();
  };

  const decreaseHandler = (i) => {
    setNewItems((prevNewItems) =>
      prevNewItems.map((item) =>
        item.sku === i.sku ? { ...item, on_hand: Math.max(item.on_hand - 1, 0) } : item
      )
    );
    setMessage(`${i.sku} Updated`);
    clearMessage();
  };

  return (
    <>
      <PageHeader title="Scanner" />
      <div className="page__body">
        <div>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <span className={`alert alert-primary m-0`}>
              {items ? items.length : 0} Available Inventory Item to Scan,
              {!items && " Please Load New Inventory Item"}
            </span>
            <div>
              <button onClick={resetHandler} className="button button__yellow">
                Reset
              </button>
              <button className="button button__blue mx-1" onClick={csvHandler}>
                Generate CSV
              </button>
            </div>
            <Link to="/inventory">
              <button className="button button__red mx-1">Go Back</button>
            </Link>
          </div>
          {message && (
            <div className={`btn ${status ? "btn-success" : "btn-danger"} d-block text-left`}>
              {message}
            </div>
          )}
          <form onSubmit={scanHandler} className="shadow__box d-flex align-items-center">
            <input
              disabled={!items}
              type="text"
              className="custom__form_input mr-2 w-100"
              placeholder="Enter SKU to Search"
              onChange={(e) => setSkuKey(e.target.value)}
              value={skuKey}
              ref={inputRef}
            />
            <button disabled={!items} className="button button__dark">
              Scan
            </button>
          </form>
        </div>

        <div>
          <table className="table my-3 bg-white">
            <thead>
              <tr>
                <th scope="col">Barcode</th>
                <th scope="col">SKU</th>
                <th scope="col">Product Name</th>
                <th scope="col">Bin Location</th>
                <th scope="col">On Hand</th>
                <th scope="col">Expected</th>
              </tr>
            </thead>
            <tbody>
              {newItem?.map((i) => (
                <tr key={i.sku}>
                  <td>{i.barcode}</td>
                  <td>{i.sku}</td>
                  <td>{i.name}</td>
                  <td>{i.bin_location}</td>
                  <td>{i.on_hand}</td>
                  <td>{i.expected}</td>
                  <td>
                    <div className="d-flex">
                      <i onClick={() => increaseHandler(i)} className="btn btn-success mr-2 fas fa-chevron-circle-up"></i>
                      <i onClick={() => decreaseHandler(i)} className="btn btn-danger fas fa-chevron-circle-down"></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const mapState = ({ items, warehouse_uid }) => {
  return {
    items,
    warehouse_uid,
  };
};

export default connect(mapState)(Scanner);
