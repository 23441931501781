import React, { useEffect, useState, useRef } from "react";
import URL from "../api";
import axios from "axios";
import Loading from "../utils/Spinner";
import sad from "../assets/sadtone.mp3";
import happy from "../assets/success.mp3";
import Tables from "../component/Tables";
import PageHeader from "../component/PageHeader";
import { CSVLink } from "react-csv";

const Transfer = () => {
  const [warehouse, setWarehouse] = useState([]);
  const [fromWarehouseId, setFromWarehouseId] = useState("");
  const [toWarehouseId, settoWarehouseId] = useState("");
  const [status, setStatus] = useState(false);
  const [skuKey, setSkuKey] = useState("");
  const [qtyKey, setQtyKey] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [itemsToUpdate, setItemsToUpdate] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [updateState, setUpdateState] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [stateUpdate, setStateUpdate] = useState(false);
  const [inUse, setInUse] = useState(true);

  useEffect(() => {
    fetch(`${URL}/warehouse`)
      .then((data) => data.json())
      .then((res) => setWarehouse(res))
      .catch((err) => console.log(err));
  }, []);

  const inputRef = useRef();
  useEffect(() => {
    if (inUse) {
      inputRef.current.focus();
    }
  });

  const loadOption = warehouse.map((i) => (
    <option key={i.id} value={i.id}>
      {i.name}
    </option>
  ));

  const updateListHandler = async (e) => {
    e.preventDefault();
    setCurrentItems([]);
    if (qtyKey === "") {
      return setMessage("Enter Qty");
    }
    if (fromWarehouseId === toWarehouseId) {
      return setMessage("You can't Select same From Warehouse and To Warehouse");
    }
  };

  const scanSkuHandler = async (e) => {
    setInUse(true);
    e.preventDefault();
    const audio = new Audio(sad);
    const audios = new Audio(happy);
    audio.pause();
    audios.pause();
    if (fromWarehouseId === toWarehouseId) {
      return setMessage("You can't Select same From Warehouse and To Warehouse");
    }
    if (skuKey) {
      setStatus(true);
      setMessage("");
      setSuccessMessage("");
      const results = await axios.post(`${URL}/single-inventory`, {
        sku: skuKey,
        warehouseUid: toWarehouseId.toString(),
      });
      var founded = "";
      const prevItems = [];
      const updateItems = itemsToUpdate;
      const displayItems = currentItems;
      founded = displayItems.find((el) => el?.sku === results.data.sku);
      const fromResults = await formHandler();
      const toResults = await formHandler2();
      if (founded) {
        // Update display and update state
        updateItems.map((el, index) => {
          if (el?.sku === founded.sku) {
            if (el?.warehouse_uuid === fromWarehouseId) {
              el.scanned += 1;
              el.on_hand -= 1;
            } else {
              el.scanned += 1;
              el.on_hand += 1;
            }
            updateItems.splice(0, 0, updateItems.splice(index, 1)[0]);
          }
          return el;
        });

        displayItems.map((el, index) => {
          if (el?.sku === founded.sku) {
            el.on_hand += 1;
            el.on_hand_other -= 1;
            el.scanned += 1;
            displayItems.splice(0, 0, displayItems.splice(index, 1)[0]);
          }
        });
        setCurrentItems(displayItems);
        setItemsToUpdate(updateItems);
        audios.play();
        setSuccessMessage("Item Quantity Increased");
      } else {
        if (fromResults !== null) {
          var toInventory = Number(toResults.available) < 0 ? 1 : Number(toResults.available + 1);
          var display = {
            sku: fromResults?.sku,
            warehouse_uuid: toWarehouseId,
            on_hand: toInventory,
            on_hand_other: Number(fromResults.available - 1),
            scanned: 1,
          };
          var toResult = {
            sku: fromResults?.sku,
            warehouse_uuid: toWarehouseId,
            on_hand: toInventory,
            scanned: 1,
            inventory_item_id: fromResults.inventory_item_id,
          };
          var fromResult = {
            sku: fromResults?.sku,
            warehouse_uuid: fromWarehouseId,
            on_hand: Number(fromResults.available - 1),
            scanned: 1,
            inventory_item_id: fromResults.inventory_item_id,
          };
          fromResult.on_hand -= Number(qtyKey);
          displayItems.push(display);
          prevItems.push(fromResult);
          prevItems.push(toResult);
          updateItems.push(fromResult);
          updateItems.push(toResult);
          setCurrentItems(displayItems);
          setItemsToUpdate(updateItems);
          setStatus(false);
          audios.play();
          setSuccessMessage("Item scanned successfully");
        }
      }
    } else {
      setMessage("Please Enter SKU");
    }
    setQtyKey("");
    setSkuKey("");
    setUpdateState(!updateState);
    setStatus(false);
  };

  const csvData = currentItems.map((item) => ({
    sku: item.sku,
    qty: item.scanned,
    batch_number: "",
  }));

  const formHandler = async () => {
    const result = await axios.post(`${URL}/single-inventory`, {
      sku: skuKey,
      warehouseUid: fromWarehouseId,
    });
    const resultItem = result.data;
    if (result.data.length <= 0) {
      setMessage("No Sku Found in From Warehouse");
      return null;
    } else {
      return resultItem;
    }
  };

  const increaseHandler = (item) => {
    setInUse(false);
    const updatedItems = currentItems.map((el) =>
      el.sku === item.sku ? { ...el, scanned: el.scanned + 1 } : el
    );
    setCurrentItems(updatedItems);
    setSuccessMessage("Item Quantity Increased");
  };

  const decreaseHandler = (item) => {
    setInUse(false);
    const updatedItems = currentItems.map((el) =>
      el.sku === item.sku && el.scanned > 1
        ? { ...el, scanned: el.scanned - 1 }
        : el
    );
    setCurrentItems(updatedItems);
    setSuccessMessage("Item Quantity Decreased");
  };

  const formHandler2 = async () => {
    const result = await axios.post(`${URL}/single-inventory`, {
      sku: skuKey,
      warehouseUid: toWarehouseId,
    });
    const resultItem = result.data;
    if (result.data.length <= 0) {
      setMessage("No Sku Found in From Warehouse");
      return null;
    } else {
      return resultItem;
    }
  };

  return (
    <>
      <PageHeader title="Transfer Between Warehouses" />
      <div className="page__body">
        {message && <div className="alert alert-danger">{message}</div>}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        <div className="shadow__box d-flex align-items-end">
          <div>
            <label className="d-block" htmlFor="">
              From Warehouse
            </label>
            <select
              defaultValue="Select Warehouse"
              className="custom__form_input mr-3"
              onChange={(e) => setFromWarehouseId(e.target.value)}
            >
              <option disabled selected>
                From Warehouse
              </option>
              {loadOption}
            </select>
          </div>
          <div className="">
            <label className="d-block" htmlFor="">
              To Warehouse
            </label>
            <select
              defaultValue="Select Warehouse"
              className="custom__form_input mr-3"
              onChange={(e) => settoWarehouseId(e.target.value)}
            >
              <option disabled selected>
                To Warehouse
              </option>
              {loadOption}
            </select>
          </div>

          <form className="d-flex align-items-center" onSubmit={scanSkuHandler}>
            <input 
              autoFocus
              type="text"
              className="custom__form_input mr-3"
              placeholder="Enter Sku"
              value={skuKey}
              onChange={(e) => setSkuKey(e.target.value)}
              disabled={status}
              ref={inputRef}
            />
          </form>

          <CSVLink
            data={csvData}
            headers={[
              { label: "SKU", key: "sku" },
              { label: "Qty to Transfer", key: "qty" },
              { label: "Batch Number", key: "batch_number" },
            ]}
            filename="transfer_data.csv"
            className="button button__dark"
          >
            Download CSV
          </CSVLink>
        </div>
        <div className="text-center">{status && <Loading />}</div>
        <table className="table my-3 bg-white">
          <thead>
            <tr>
              <th scope="col" className="warehouse-number">warehouse_uuid</th>
              <th scope="col">SKU</th>
              <th scope="col">Scanned</th>
              <th scope="col">On Hand From Warehouse</th>
              <th scope="col">On Hand To Warehouse</th>
              <th scope="col">Manual Adjustment</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((k) => (
              <tr key={k?.sku + k?.warehouse_uuid}>
                <td className="warehouse-number">{k?.warehouse_uuid}</td>
                <td>{k?.sku}</td>
                <td>{k?.scanned}</td>
                <td>{k?.on_hand_other}</td>
                <td>{k?.on_hand}</td>
                <td>
                  <div className="d-flex">
                    <i
                      onClick={() => increaseHandler(k)}
                      className="btn btn-success mr-2 fas fa-chevron-circle-up"
                    ></i>
                    <i
                      onClick={() => decreaseHandler(k)}
                      className="btn btn-danger fas fa-chevron-circle-down"
                    ></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Transfer;
