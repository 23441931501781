import React, { useState } from "react";
import URL from "../api";
import axios from "axios";
import Spinner from "../utils/Spinner";
import PageHeader from "../component/PageHeader";

const AddInventory = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const dataHandler = (file) => {
    setFile(file);
  };

  const uploadHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("filename", `${file.name}`);
    formData.append("uploadedFile", file);

    const result = await axios.post(`${URL}/uploadInventory`, formData);
    console.log(result);
    if (result.data.message) {
      setMessage("Uploaded Successfully");
    } else if (result.data.sqlMessage) {
      setMessage("Error, Please check if the file is formatted correctly");
    }
    setLoading(false);
  };

  return (
    <>
      <PageHeader title="Upload Inventory File" />
      <div className="page__body">
        <div className="text-center">{loading && <Spinner />}</div>
        <form onSubmit={(e) => uploadHandler(e)}>
          <label className="FileLabel" htmlFor="inventoryFile">
            Upload Inventory File
          </label>
          <input
            disabled={loading}
            onChange={(e) => dataHandler(e.target.files[0])}
            type="file"
            name="inventory"
            id="inventoryFile"
          />
          <button
            disabled={loading}
            type="submit"
            className="button button__blue w-100 mt-3"
          >
            Submit
          </button>
        </form>
        {message && (
          <div className="alert alert-primary my-2 text-center">{message}</div>
        )}
      </div>
    </>
  );
};

export default AddInventory;
