import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h3 className="text-white">The Suit Depot</h3>
      <nav>
        <h4 className="lead text-uppercase">Navigation</h4>
        <ul>
          <li>
            <NavLink exact activeClassName="selected" to="/inventory">
              <i className="fas fa-qrcode mr-2"></i> Stock Take
            </NavLink>
          </li>
          <li>
            <NavLink exact activeClassName="selected" to="/remove-inventory">
            <i className="fas fa-minus-square mr-2"></i> Remove From Stock
            </NavLink>
          </li>
          <li>
            <NavLink exact activeClassName="selected" to="/additional-inventory">
            <i className="fas fa-plus-square mr-2"></i> Add Qty
            </NavLink>
          </li>
          <li>
            <NavLink exact activeClassName="selected" to="/transfer">
              <i className="fas fa-exchange-alt mr-2"></i> Transfer Between
              Warehouses
            </NavLink>
          </li>
          <li>
            <NavLink exact activeClassName="selected" to="/stock-movements">
              <i className="fas fa-exchange-alt mr-2"></i> Stock Movements
            </NavLink>
          </li>
          <li>
            <NavLink exact activeClassName="selected" to="/sales-report">
              <i className="fas fa-exchange-alt mr-2"></i> Sales Reports
            </NavLink>
          </li>
          <li>
            <NavLink exact activeClassName="selected" to="/bw-sf-transfer">
              <i className="fas fa-exchange-alt mr-2"></i> BW To SF Transfer
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
