import React, { useEffect, useState, useRef } from "react";
import URL from "../api";
import axios from "axios";
import sad from "../assets/sadtone.mp3";
import Loading from "../utils/Spinner";
import PageHeader from "../component/PageHeader";

const SendToClearance = () => {
  const [warehouse, setWarehouse] = useState([]);
  const [warehouseSelected, setWarehouseSelected] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [skuKey, setSkuKey] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [status, setStatus] = useState(false);
  const [stateUpdate, setStateUpdate] = useState(false);

  useEffect(() => {
    clearMessage();
  }, [message, success]);

  useEffect(() => {
    fetch(`${URL}/warehouse`)
      .then((data) => data.json())
      .then((res) => setWarehouse(res))
      .catch((err) => console.log(err));
  }, []);

  const clearMessage = () => {
    setTimeout(() => {
      setMessage("");
      setSuccess("");
    }, 3000);
  };

  const loadOption = warehouse.map((i) => {
    return (
      <option key={i.uuid} value={i.uuid}>
        {i.name}
      </option>
    );
  });

  const searchSku = async (e) => {
    e.preventDefault();
    const audio = new Audio(sad);
    audio.pause();
    setStatus(true);
    setSuccess("");

    if (skuKey) {
      setMessage("");
      const results = await axios.post(`${URL}/single-inventory`, {
        sku: skuKey,
        warehouseUid: warehouseSelected,
      });
      const prevItems = currentItems;
      prevItems.scanned = 0;
      //const founded = prevItems.find((el) => el?.sku === skuKey);
      var founded = '';
      if (!results.data[0]) {
        founded = false;
      } else {
        founded = prevItems.find((el) => el?.sku === results.data[0].sku);
      }
      
      if (founded) {
        prevItems.map((el, index) => {
          if (el?.sku === founded.sku) {
            el.scanned += 1;
            //el.on_hand += 1;
            prevItems.splice(0, 0, prevItems.splice(index, 1)[0]);
          }
          return el;
        });
        setCurrentItems(prevItems);
        setSuccess("Item Quantity Increased");
      } else {
        const result = await axios.post(`${URL}/single-inventory`, {
          sku: skuKey,
          warehouseUid: warehouseSelected,
        });
        const resultItem = result.data[0];
        console.log(resultItem);
        //resultItem.scanned = 0;
        if (result.data.length <= 0) {
          audio.play();
          setMessage("No Sku Found");
        } else {
          resultItem.on_hand += 1;
          resultItem.scanned = 1;
          prevItems.unshift(resultItem);
          setCurrentItems(prevItems);
          setSuccess("Item Quantity Increased");
        }
      }
    } else {
      setMessage("Please Select Warehouse and SKU");
    }
    setStatus(false);
    setStateUpdate(!stateUpdate);
    setSkuKey("");
  };
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  });

  const updateHandler = async () => {
    setStatus(true);
    const newarr = [];
    currentItems.map((i) => {
      const obj = {
        sku: i.sku,
        warehouse_uuid: warehouseSelected,
        on_hand: i.on_hand,
        bin_location: "Clearance",
      };
      return newarr.push(obj);
    });
    console.log(newarr);
    const res = await axios.put(`${URL}/update-sellbrite`, {
      data: newarr,
    });
    console.log(res.status);
    if (res.status == 200) {
      setSuccess("Items Updated On Sellbrite");
      setCurrentItems([]);
    }
    setStatus(false);
  };

  const increaseHandler = (index) => {
    const prevItems = currentItems;
    const item = prevItems[index];
    item.on_hand += 1;
    item.scanned += 1;
    console.log(prevItems);
    setCurrentItems(prevItems);
    setStateUpdate(!stateUpdate);
  };

  const decreaseHandler = (index) => {
    const prevItems = currentItems;
    const item = prevItems[index];
    item.on_hand -= 1;
    item.scanned -= 1;
    console.log(prevItems);
    setCurrentItems(prevItems);
    setStateUpdate(!stateUpdate);
  };

  return (
    <>
      <PageHeader title="Add Quantity" />
      <div className="page__body">
        {message && <div className="alert alert-danger">{message}</div>}
        {success && <div className="alert alert-success">{success}</div>}
        <div className="shadow__box d-flex justify-content-center align-items-center">

          <form className="form d-flex w-100" onSubmit={searchSku}>
            <input
              autoFocus
              type="text"
              className="custom__form_input mr-2 w-100"
              placeholder="Enter Sku"
              value={skuKey}
              onChange={(e) => setSkuKey(e.target.value)}
              disabled={status}
              ref={inputRef}
            />
            <button disabled={status} className="button button__dark">
              Submit
            </button>
          </form>
        </div>

        <div className="text-center">{status && <Loading />}</div>
        {currentItems.length > 0 && (
          <>
            <div className="d-flex justify-content-between align-items-center my-4">
              <h5 className="m-0">{currentItems.length} Items to Update</h5>
              <button
                onClick={updateHandler}
                disabled={status}
                className="button button__blue"
              >
                Update on Sellbrite
              </button>
            </div>
            <table className="table my-3 bg-white">
              <thead>
                <tr>
                  <th scope="col">warehouse_uuid</th>
                  <th scope="col">SKU</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Bin Location</th>
                  <th scope="col">On Hand</th>
                </tr>
              </thead>

              <tbody>
                {currentItems?.map((k, i) => (
                  <tr key={k?.sku + k?.warehouse_uuid}>
                    <td>{k?.warehouse_uuid}</td>
                    <td>{k?.sku}</td>
                    <td>{k?.product_name}</td>
                    <td>{k?.bin_location}</td>
                    <td>{k?.on_hand}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default SendToClearance;
