//Export single component in react.js
import React from "react";

const SuccessPage = () => {
    return (
      <div>
        <h1>Great Success!</h1>
      </div>
    )
  };

export default SuccessPage;