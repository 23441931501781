import React, { useState } from "react";
import URL from "../api";
import axios from "axios";
import Spinner from "../utils/Spinner";
import PageHeader from "../component/PageHeader";

const SalesReport = () => {
    const [category, setCategory] = useState("");
    const [size, setSize] = useState("");
    const [material, setMaterial] = useState("");
    const [color, setColor] = useState("");
    const [brand, setBrand] = useState("");
    const [fit, setFit] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [inventoryItem, setInventoryItem] = useState(null);

    const options = [
        {
            label: "Suits",
            value: "suits"
        },
        {
            label: "Blazers",
            value: "blazers"
        },
        {
            label: "Shirts",
            value: "shirts"
        },
        {
            label: "Pants",
            value: "pants"
        },
        {
            label: "Shoes",
            value: "shoes"
        },
        {
            label: "Coats",
            value: "coats"
        }
    ];

    const reportItems = async (event) => {
      event.preventDefault();
      //setError("");
      setInventoryItem(null);
      setisLoading(true);
      const result = await axios({
        method: "post",
        url: `${URL}/inventory-trace`,
        data: {
          //sku: bin,
          //warehouse: wareId
        },
      });
        alert("Something");
    };
    return (
        <>
        <PageHeader title="Upload Inventory File" />
        <div className="page__body">
            <div className="text-center">{isLoading && <Spinner />}</div>
              <form onSubmit={reportItems} className="w-100">
                <div className="d-flex align-items-center">
                  <select
                    defaultValue="Select Category"
                    className="custom__form_input mr-2 w-100"
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                  </select>
                  <input
                    disabled={isLoading}
                    type="text"
                    className="custom__form_input mr-2 w-100"
                    onChange={(e) => setBrand(e.target.value)}
                    placeholder="Enter Brand"
                    value={brand}
                  />
                  <input
                    disabled={isLoading}
                    type="text"
                    className="custom__form_input mr-2 w-100"
                    onChange={(e) => setColor(e.target.value)}
                    placeholder="Enter Color"
                    value={color}
                  />
                  <input
                    disabled={isLoading}
                    type="text"
                    className="custom__form_input mr-2 w-100"
                    onChange={(e) => setSize(e.target.value)}
                    placeholder="Enter Size"
                    value={size}
                  />
                  <input
                    disabled={isLoading}
                    type="text"
                    className="custom__form_input mr-2 w-100"
                    onChange={(e) => setFit(e.target.value)}
                    placeholder="Enter Fit"
                    value={fit}
                  />
                  <input
                    disabled={isLoading}
                    type="text"
                    className="custom__form_input mr-2 w-100"
                    onChange={(e) => setMaterial(e.target.value)}
                    placeholder="Enter Material"
                    value={material}
                  />
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="button button__dark"
                  >
                    Search
                  </button>
                </div>
            </form>
            {message && (
            <div className="alert alert-primary my-2 text-center">{message}</div>
            )}
                    <div className="mt-5 text-center">{isLoading && <Spinner />}</div>
        {inventoryItem && (
          <>
            <div className="d-flex justify-content-between align-items-center my-4">
              <h5 className="m-0">Found {inventoryItem.length} Items</h5>
            </div>

            <div className="inventory_item">
              <table className="table my-3 bg-white">
                <thead>
                  <tr>
                    <th scope="col">SKU</th>
                    <th scope="col">Inventory at beginning of period</th>
                    <th scope="col">In Stock Variants</th>
                    <th scope="col">Sales</th>
                    <th scope="col">Sellthrough Rate</th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  {inventoryItem?.map((k) => (
                    <tr key={k?.sku + k?.warehouse_uuid}>
                      <td>{k?.sku}</td>
                      <td>{k?.org_inventory}</td>
                      <td>{k.in_stock_variants}</td>
                      <td>{k?.sales}</td>
                      <td>%{k?.sellthrough}</td>
                      <td>{k?.transaction_date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        </div>
        </>
    )
};

export default SalesReport;