import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import URL from "../api.js";
// import Table from "../component/Table.js";
import Spinner from "../utils/Spinner";
import axios from "axios";
import PageHeader from "../component/PageHeader.js";

const Inventory = ({ setItems, setUuid }) => {
  const [warehouse, setWarehouse] = useState([]);
  const [warehouseSelected, setWarehouseSelected] = useState("");
  const [inventoryItem, setInventoryItem] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [error, setError] = useState("");

  const history = useHistory();

  useEffect(() => {
    fetch(`${URL}/warehouse`)
      .then((data) => data.json())
      .then((res) => setWarehouse(res))
      .catch((err) => console.log(err));

    setItems(null);
    setUuid(null);
  }, [setItems, setUuid]);

  useEffect(() => {
    localStorage.removeItem("oldItems");
  }, []);

  const loadOption = warehouse.map((i) => {
    return (
      <option key={i.uuid} value={i.uuid}>
        {i.name}
      </option>
    );
  });

  const searchItem = async (event) => {
    if (warehouseSelected !== "" && keyword !== "") {
      event.preventDefault();
      setError("");
      setInventoryItem(null);
      setisLoading(true);
      const bin = keyword;
      const result = await axios({
        method: "post",
        url: `${URL}/searchInventory`,
        data: {
          bin: bin,
          warehouse: warehouseSelected
        },
      });
      setInventoryItem(await result.data);
      localStorage.setItem("oldItems", JSON.stringify(result.data));
      setisLoading(false);
      setError("");
      console.log(JSON.parse(localStorage.getItem("oldItems")));
    } else {
      event.preventDefault();
      setError("Please Select a warehouse and Enter Bin by Location");
    }
  };

  const setItemsHandeler = async () => {
    if (warehouseSelected !== "" && inventoryItem !== null && keyword !== null) {
      setItems(inventoryItem);
      history.push("/scanner");
    } else {
      setError("Please Select a warehouse and Enter Bin by Location");
    }
  };

  const setWarehouseHand = (value) => {
    setWarehouseSelected(value);
    setUuid(value);
  };

  return (
    <>
      <PageHeader title="Stock Take" />
      <div className="page__body">
        {error && <span className="alert alert-danger d-block">{error}</span>}
        <div className="shadow__box d-flex align-items-center">
          <select
            defaultValue="Select Warehouse"
            className="custom__form_input mr-2 w-100"
            onChange={(e) => setWarehouseHand(e.target.value)}
          >
            <option disabled selected>
              Select Warehouse
            </option>
            {loadOption}
          </select>
          <form onSubmit={searchItem} className="w-100">
            <div className="d-flex align-items-center">
              <input
                disabled={isLoading}
                type="text"
                className="custom__form_input mr-2 w-100"
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Enter Bin Location"
                value={keyword}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="button button__dark"
              >
                Search
              </button>
            </div>
          </form>
        </div>

        <div className="mt-5 text-center">{isLoading && <Spinner />}</div>
        {inventoryItem && (
          <>
            <div className="d-flex justify-content-between align-items-center my-4">
              <h5 className="m-0">Found {inventoryItem.length} Items</h5>
              <button
                onClick={setItemsHandeler}
                className="button button__dark"
                disabled={isLoading}
              >
                Go to Scan SKU
              </button>
            </div>

            <div className="inventory_item">
              <table className="table my-3 bg-white">
                <thead>
                  <tr>
                    <th scope="col">SKU</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Bin Location</th>
                    <th scope="col">On Hand</th>
                  </tr>
                </thead>

                <tbody>
                  {inventoryItem?.map((k) => (
                    <tr key={k?.sku + k?.warehouse_uuid}>
                      <td>{k?.sku}</td>
                      <td>{k?.name}</td>
                      <td>{k?.bin_location}</td>
                      <td>{k?.on_hand}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapDispatch = (Dispatch) => {
  return {
    setItems: (fillIt) => Dispatch({ type: "SET_SCAN_ITEM", filItems: fillIt }),
    setUuid: (uuid) => Dispatch({ type: "SET_UUID", uuid: uuid }),
  };
};

export default connect(null, mapDispatch)(Inventory);
