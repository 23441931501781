import React, { useEffect, useState, useRef } from "react";
import URL from "../api";
import axios from "axios";
import sad from "../assets/sadtone.mp3";
import happy from "../assets/success.mp3";
import Loading from "../utils/Spinner";
import PageHeader from "../component/PageHeader";

const RemoveInventory = () => {
  const [warehouse, setWarehouse] = useState([]);
  const [warehouseSelected, setWarehouseSelected] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [skuKey, setSkuKey] = useState("");
  const [reasonKey, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [status, setStatus] = useState(false);
  const [stateUpdate, setStateUpdate] = useState(false);
  const [inUse, setInUse] = useState(true);

  useEffect(() => {
    clearMessage();
  }, [message, success]);

  useEffect(() => {
    fetch(`${URL}/warehouse`)
      .then((data) => data.json())
      .then((res) => setWarehouse(res))
      .catch((err) => console.log(err));
  }, []);

  const clearMessage = () => {
    setTimeout(() => {
      setMessage("");
      setSuccess("");
    }, 3000);
  };

  const loadOption = warehouse.map((i) => {
    return (
      <option key={i.id} value={i.id}>
        {i.name}
      </option>
    );
  });

  const options = [
    {
      label: "Breaking Up A Suit",
      value: "breaksuit",
    },
    {
      label: "Damaged",
      value: "damaged",
    },
    {
      label: "Sent To FBA",
      value: "fba"
    }
  ];

  const searchSku = async (e) => {
    setInUse(true);
    e.preventDefault();
    const audio = new Audio(sad);
    const audios = new Audio(happy);
    audio.pause();
    audios.pause();
    setStatus(true);
    setSuccess("");

    if (warehouseSelected && skuKey) {
      setMessage("");
      const results = await axios.post(`${URL}/single-inventory`, {
        sku: skuKey,
        warehouseUid: warehouseSelected,
      });
      const prevItems = currentItems;
      prevItems.scanned = 0;
      //const founded = prevItems.find((el) => el?.sku === skuKey);
      //const founded = prevItems.find((el) => el?.sku === results.data[0].sku);
      var founded = '';
      if (!results) {
        founded = false;
      } else {
        founded = prevItems.find((el) => el?.sku === results.data.sku);
      }

      if (founded) {
        prevItems.map((el, index) => {
          if (el?.sku === founded.sku) {
            el.scanned += 1;
            el.available -= 1;
            prevItems.splice(0, 0, prevItems.splice(index, 1)[0]);
          }
          return el;
        });
        setCurrentItems(prevItems);
        audios.play();
        setSuccess("Item Quantity Increased");
      } else {
        const result = await axios.post(`${URL}/single-inventory`, {
          sku: skuKey,
          warehouseUid: warehouseSelected,
        });
        
        const resultItem = await result.data;
        if (resultItem.length <= 0) {
          audio.play();
          setMessage("No Sku Found");
        } else {
          resultItem.available -= 1;
          resultItem.scanned = 1;
          resultItem.reason = reasonKey;
          prevItems.push(resultItem);
          setCurrentItems(prevItems);
          audios.play();
          setSuccess("Item Quantity Decreased");
        }
      }
    } else {
      setMessage("Please Select Warehouse and SKU");
    }
    setStatus(false);
    setStateUpdate(!stateUpdate);
    setSkuKey("");
  };
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  });

  const updateHandler = async () => {
    setStatus(true);
    const newarr = [];
    currentItems.map((i) => {
      const obj = {
        location_id: warehouseSelected.toString(),
        inventory_item_id: i.inventory_item_id.toString(),
        available: i.available.toString(),
	adjustment: i.scanned.toString(),
        reason: i.reason.toString(),
        note: "Decrease"
      };
      return newarr.push(obj);
    });
    console.log(newarr);
    const res = await axios.put(`${URL}/update-sellbrite`, {
      data: newarr,
    });
    console.log(res.status);
    if (res.status === 200) {
      setSuccess("Items Updated On Shopify");
      setCurrentItems([]);
    }
    setStatus(false);
  };

  const increaseHandler = (index) => {
    const prevItems = currentItems;
    const item = prevItems[index];
    item.available -= 1;
    item.scanned += 1;
    console.log(prevItems);
    setCurrentItems(prevItems);
    setStateUpdate(!stateUpdate);
  };

  const decreaseHandler = (index) => {
    const prevItems = currentItems;
    const item = prevItems[index];
    item.available += 1;
    item.scanned -= 1;
    console.log(prevItems);
    setCurrentItems(prevItems);
    setStateUpdate(!stateUpdate);
  };

  const manualQtyHandler = (index, qty) => {
    const prevItems = currentItems;
    const item = prevItems[index];
    item.available = qty;
    item.scanned = qty;
    console.log(prevItems);
    setCurrentItems(prevItems);
    setStateUpdate(!stateUpdate);
  };

  return (
    <>
      <PageHeader title="Remove Quantity" />
      <div className="page__body">
        {message && <div className="alert alert-danger">{message}</div>}
        {success && <div className="alert alert-success">{success}</div>}
        <div className="shadow__box d-flex justify-content-center align-items-center">
          <select
            defaultValue="Select Warehouse"
            className="custom__form_input mr-2 w-50"
            onChange={(e) => setWarehouseSelected(e.target.value)}
          >
            <option disabled selected>
              Select Warehouse
            </option>
            {loadOption}
          </select>

          <form className="form d-flex w-100" onSubmit={searchSku}>
          <select
              defaultValue="Reason For Adjustment"
              className="custom__form_input mr-2 w-50"
              onChange={(e) => setReason(e.target.value)}>
            {options.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
            </select>
            <input
              autoFocus={inUse}
              type="text"
              className="custom__form_input mr-2 w-100"
              placeholder="Enter Sku"
              value={skuKey}
              onChange={(e) => setSkuKey(e.target.value)}
              disabled={status}
              ref={inputRef}
            />
            <button disabled={status} className="button button__dark">
              Submit
            </button>
          </form>
        </div>

        <div className="text-center">{status && <Loading />}</div>
        {currentItems.length > 0 && (
          <>
            <div className="d-flex justify-content-between align-items-center my-4">
              <h5 className="m-0">{currentItems.length} Items to Update</h5>
              <button
                onClick={updateHandler}
                disabled={status}
                className="button button__blue"
              >
                Update on Shopify
              </button>
            </div>
            <table className="table my-3 bg-white">
              <thead>
                <tr>
                  <th scope="col" className="warehouse-number">warehouse_uuid</th>
                  <th scope="col">SKU</th>
                  <th scope="col">Scanned</th>
                  <th scope="col">On Hand</th>
                  <th scope="col">Increase / Decrease</th>
                </tr>
              </thead>

              <tbody>
                {currentItems?.map((k, i) => (
                  <tr key={k?.sku + k?.warehouse_uuid}>
                    <td className="warehouse-number">{k?.warehouse_uuid}</td>
                    <td>{k?.sku}</td>
                    <td>{k?.scanned}</td>
                    <td>{k?.available}</td>
                    <td>                      
                      <input
                        type="number"
                        name="5505ef96-78c3-4a3e-9be3-17554e962600"
                        placeholder="Enter QTY on Item"
                        className="form-control"
                        onChange={(e) =>
                          manualQtyHandler(
                            i,
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <div className="d-flex">
                        <i
                          onClick={() => increaseHandler(i)}
                          class="btn btn-success mr-2 fas fa-chevron-circle-up"
                        ></i>
                        <i
                          onClick={() => decreaseHandler(i)}
                          class="btn btn-danger fas fa-chevron-circle-down"
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default RemoveInventory;
