const initialState = {
  items: [],
  warehouse_uid: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SCAN_ITEM":
      return {
        ...state,
        items: action.filItems,
      };

    case "SET_UUID":
      return {
        ...state,
        warehouse_uid: action.uuid,
      };

    default:
      return state;
  }
};

export default reducer;
