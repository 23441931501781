const inputs = {
  sharedInputs: [
    {
      label: "Category",
      name: "category_name",
      select: true,
      option: ["Suits", "Blazers", "Outerwear", "Shirts", "Pants", "Shoes", "Accessories", "Boys"],
      required: true,
    },
    {
      label: "Title",
      name: "name",
      select: false,
      type: "text",
      required: true,
    },
    {
      label: "Parent SKU",
      name: "parent_sku",
      select: false,
      type: "text",
      required: false,
    },
    {
      label: "Condition",
      name: "condition",
      select: true,
      option: ["new"],
      required: false,
    },
    {
      label: "Brand",
      name: "brand",
      select: false,
      type: "text",
      required: false,
    },
    {
      label: "Manufacturer",
      name: "manufacturer",
      select: false,
      type: "text",
      required: false,
    },
    {
      label: "MSRP",
      name: "msrp",
      select: false,
      type: "number",
      required: false,
    },
    {
      label: "Price",
      name: "price",
      select: false,
      type: "number",
      required: false,
    },
    {
      label: "Bullet 1",
      name: "feature1",
      select: false,
      type: "text",
      required: false
    },
    {
      label: "Bullet 2",
      name: "feature2",
      select: false,
      type: "text",
      required: false
    },
    {
      label: "Bullet 3",
      name: "feature3",
      select: false,
      type: "text",
      required: false
    },
    {
      label: "Bullet 4",
      name: "feature4",
      select: false,
      type: "text",
      required: false
    },
    {
      label: "Bullet 5",
      name: "feature5",
      select: false,
      type: "text",
      required: false
    },
    {
      label: "Description",
      name: "description",
      select: false,
      type: "textarea",
      required: false
    },
  ],
  suitForm: [
    {
      label: "Color",
      name: "color",
      select: true,
      option: [
        "Black",
        "Blue",
        "Brown",
        "Gray",
        "White",
        "Beige",
        "Red",
        "Green",
        "Pink",
        "Purple",
      ],
      required: true,
      isCustom: true,
    },
    {
      label: "Fit",
      name: "fit",
      select: true,
      option: ["Regular Fit", "Slim Fit", "Modern Fit"],
      required: true,
      isCustom: true,
    },
    {
      label: "Material",
      name: "material",
      select: true,
      option: ["100% Wool", "100% Cotton", "Wool Blend", "Man Made Materials"],
      required: true,
      isCustom: true,
    },
    {
      label: "Pattern",
      name: "pattern",
      select: true,
      option: ["Solid", "Striped", "Checkered", "Plaid", "Textured"],
      required: true,
      isCustom: true,
    },
    {
      label: "Canvassing",
      name: "canvassing",
      select: true,
      option: ["Half Canvassed", "Fully Canvassed", "Non Canvassed"],
      required: true,
      isCustom: true,
    },
    {
      label: "Waist Size",
      name: "waist",
      select: true,
      option: ["Drop 6", "Drop 7", "Drop 5", "Portly"],
      required: true,
      isCustom: true,
    },
    {
      label: "Lapels",
      name: "lapels",
      select: true,
      option: ["Notch Lapels", "Shawl Lapel", "Narrow Lapels", "Peak Lapels"],
      required: true,
      isCustom: true,
    },
    {
      label: "Number Of Buttons",
      name: "buttons",
      select: true,
      option: ["One Button", "Two Button", "Three Button", "Double Breasted"],
      required: true,
      isCustom: true,
    },
    {
      label: "Pleats",
      name: "pleats",
      select: true,
      option: ["Flat Front", "Single Pleat", "Double Pleat"],
      required: true,
      isCustom: true,
    },
    {
      label: "Vents",
      name: "vents",
      select: true,
      option: ["Non-Vented", "Single Rear Vent", "Dual Side Vents"],
      required: true,
      isCustom: true,
    },
    {
      label: "Ticket Pocket",
      name: "ticket",
      select: true,
      option: ["Yes", "No"],
      required: true,
      isCustom: true,
    },
    {
      label: "Number of pieces",
      name: "pieces",
      select: true,
      option: ["2", "3"],
      required: true,
      isCustom: true,
    },
  ],
  blazerForm: [
    {
      label: "Color",
      name: "color",
      select: true,
      option: [
        "Black",
        "Blue",
        "Brown",
        "Gray",
        "White",
        "Beige",
        "Red",
        "Green",
        "Pink",
        "Purple",
      ],
      required: true,
      isCustom: true,
    },
    {
      label: "Fit",
      name: "fit",
      select: true,
      option: ["Regular Fit", "Slim Fit", "Modern Fit"],
      required: true,
      isCustom: true,
    },
    {
      label: "Material",
      name: "material",
      select: true,
      option: ["100% Wool", "100% Cotton", "Wool Blend", "Man Made Materials"],
      required: true,
      isCustom: true,
    },
    {
      label: "Pattern",
      name: "pattern",
      select: true,
      option: ["Solid", "Striped", "Checkered", "Plaid", "Textured"],
      required: true,
      isCustom: true,
    },
    {
      label: "Canvassing",
      name: "canvassing",
      select: true,
      option: ["Half Canvassed", "Fully Canvassed", "Non Canvassed"],
      required: true,
      isCustom: true,
    },
    {
      label: "Lapels",
      name: "lapels",
      select: true,
      option: ["Notch Lapels", "Shawl Lapel", "Narrow Lapels", "Peak Lapels"],
      required: true,
      isCustom: true,
    },
    {
      label: "Number Of Buttons",
      name: "buttons",
      select: true,
      option: ["One Button", "Two Button", "Three Button", "Double Breasted"],
      required: true,
      isCustom: true,
    },
    {
      label: "Vents",
      name: "vents",
      select: true,
      option: ["Non-Vented", "Single Rear Vent", "Dual Side Vents"],
      required: true,
      isCustom: true,
    },
    {
      label: "Ticket Pocket",
      name: "ticket",
      select: true,
      option: ["Yes", "No"],
      required: true,
      isCustom: true,
    },
  ],
  boysForm: [
    {
      label: "Color",
      name: "color",
      select: true,
      option: [
        "Black",
        "Blue",
        "Brown",
        "Gray",
        "White",
        "Beige",
        "Red",
        "Green",
        "Pink",
        "Purple",
      ],
      required: true,
      isCustom: true,
    },
    {
      label: "Fit",
      name: "fit",
      select: true,
      option: ["Regular Fit", "Slim Fit", "Husky Fit"],
      required: true,
      isCustom: true,
    },
    {
      label: "Material",
      name: "material",
      select: true,
      option: ["100% Wool", "100% Cotton", "Wool Blend", "Man Made Materials"],
      required: true,
      isCustom: true,
    },
    {
      label: "Pattern",
      name: "pattern",
      select: true,
      option: ["Solid", "Striped", "Checkered", "Plaid", "Textured"],
      required: true,
      isCustom: true,
    },
    {
      label: "Lapels",
      name: "lapels",
      select: true,
      option: ["Notch Lapels", "Shawl Lapel", "Narrow Lapels", "Peak Lapels"],
      required: true,
      isCustom: true,
    }
  ],
  shirtForm: [
    {
      label: "Color",
      name: "color",
      select: true,
      option: [
        "Black",
        "Blue",
        "Brown",
        "Gray",
        "White",
        "Beige",
        "Red",
        "Green",
        "Pink",
        "Purple",
        "Orange"
      ],
      required: true,
      isCustom: true,
    },
    {
      label: "Fit",
      name: "fit",
      select: true,
      option: ["Regular Fit", "Slim Fit", "Modern Fit"],
      required: true,
      isCustom: true,
    },
    {
      label: "Material",
      name: "material",
      select: true,
      option: ["100% Cotton", "Cotton Blend", "Man Made Materials"],
      required: true,
      isCustom: true,
    },
    {
      label: "Pattern",
      name: "pattern",
      select: true,
      option: ["Solid", "Striped", "Checkered", "Plaid", "Textured"],
      required: true,
      isCustom: true,
    },
    {
      label: "Collar Style",
      name: "collar",
      select: true,
      option: ["Cutaway", "Spread", "Point", "Button-Down"],
      required: true,
      isCustom: true,
    },
  ],
  coatForm: [
    {
      label: "Fit",
      name: "fit",
      select: true,
      option: ["Regular Fit", "Slim Fit"],
      required: true,
      isCustom: true,
    },
    {
    label: "Color",
    name: "color",
    select: true,
    option: [
      "Black",
      "Blue",
      "Brown",
      "Gray",
      "White",
      "Beige",
      "Red",
      "Green",
      "Pink",
      "Purple",
    ],
    required: true,
    isCustom: true,
  },
  {
    label: "Material",
    name: "material",
    isCustom: true,
    select: true,
    option: [
      "Wool",
      "Leather",
      "Wool Cashmere Blend",
      "Man Made Materials"
    ],
    required: true
  },
  {
    label: "Style",
    name: "style",
    isCustom: true,
    select: true,
    option: [
      "Trench Coat",
      "Rain Coat",
      "Puffer Jacket",
      "Overcoat",
      "Parka",
      "Windbreaker",
      "Pea Coat"
    ],
    required: true
  },
  {
    label: "Closure",
    name: "closure",
    isCustom: true,
    select: true,
    option: [
      "Toggle",
      "Button",
      "Buckle",
      "Tie",
      "Zipper",
      "Snap"
    ],
    required: true
  },
  ],
  pantsForm: [
    {
      label: "Fit",
      name: "fit",
      select: true,
      option: ["Regular Fit", "Slim Fit"],
      required: true,
      isCustom: true,
    },
    {
      label: "Front",
      name: "front",
      select: true,
      option: ["Flat Front", "Single Pleat", "Double Pleated"],
      required: true,
      isCustom: true,
    },
    {
    label: "Color",
    name: "color",
    select: true,
    option: [
      "Black",
      "Blue",
      "Brown",
      "Gray",
      "White",
      "Beige",
      "Red",
      "Green",
      "Pink",
      "Purple",
    ],
    required: true,
    isCustom: true,
  },
  {
    label: "Material",
    name: "material",
    isCustom: true,
    select: true,
    option: [
      "Wool",
      "Leather",
      "Wool Cashmere Blend",
      "Cotton",
      "Cotton Blend",
      "Man Made Materials"
    ],
    required: true
  },
  {
    label: "Style",
    name: "style",
    isCustom: true,
    select: true,
    option: [
      "Dress Pants",
      "Casual Pants",
      "Chinos",
      "Jeans"
    ],
    required: true
  },
  {
    label: "Hem",
    name: "hem",
    isCustom: true,
    select: true,
    option: [
      "Pre-Hemmed",
      "Unfinished"
    ],
    required: true
  },
  {
    label: "Closure",
    name: "closure",
    isCustom: true,
    select: true,
    option: [
      "Toggle",
      "Button",
      "Buckle",
      "Tie",
      "Zipper",
      "Snap"
    ],
    required: true
  },
  ],
  shoeForm: [
    {
      label: "Color",
      name: "color",
      select: true,
      option: [
        "Black",
        "Blue",
        "Brown",
        "Gray",
        "White",
        "Beige",
        "Red",
        "Green",
        "Pink",
        "Purple",
      ],
      required: true,
      isCustom: true,
    },
    {
      label: "Material",
      name: "material",
      isCustom: true,
      select: true,
      option: [
        "Leather",
        "Canvas",
        "Man Made Materials"
      ],
      required: true
    },
    {
      label: "Sole",
      name: "sole",
      isCustom: true,
      select: true,
      option: [
        "Leather",
        "Rubber",
        "Man Made Materials"
      ],
      required: true
    },
    {
      label: "Style",
      name: "style",
      isCustom: true,
      select: true,
      option: [
        "Oxford",
        "Brogue",
        "Monk Strap",
        "Derby",
        "Loafer"
      ],
      required: true
    },
    {
      label: "Closure",
      name: "closure",
      isCustom: true,
      select: true,
      option: [
        "Lace Up",
        "Slip On",
        "Buckle",
      ]
    },
    {
      label: "Width",
      name: "width",
      isCustom: true,
      select: true,
      option: [
        "A",
        "AA",
        "AAA",
        "B",
        "C",
        "D",
        "E",
        "EE",
        "EEE",
        "EEEE",
      ],
      required: true,
    },
  ],
  accessoriesForm: [
    {
      label: "Color",
      name: "color",
      select: true,
      option: [
        "Black",
        "Blue",
        "Brown",
        "Gray",
        "White",
        "Beige",
        "Red",
        "Green",
        "Pink",
        "Purple",
      ],
      required: true,
      isCustom: true,
    },
    {
      label: "Material",
      name: "material",
      isCustom: true,
      select: true,
      option: [
        "Silk",
        "Cotton",
        "Polyester",
        "Leather"
      ],
      required: true
    },
  ],
};

export default inputs;
