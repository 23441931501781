import React from "react";

const PageHeader = ({ title }) => {
  return (
    <div className="page__header">
      <h2>{title}</h2>
      <h3>
        Welcome Back <i className="fas fa-hand-sparkles"></i>
      </h3>
    </div>
  );
};

export default PageHeader;
