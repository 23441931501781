import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import URL from "../api.js";
// import Table from "../component/Table.js";
import Spinner from "../utils/Spinner";
import axios from "axios";
import PageHeader from "../component/PageHeader.js";

const Inventory = ({ setItems, setUuid }) => {
  const [warehouse, setWarehouse] = useState([]);
  const [warehouseSelected, setWarehouseSelected] = useState("");
  const [inventoryItem, setInventoryItem] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [movementType, setMovementType] = useState("");
  const [stockTake, setStockTake] = useState("");
  const [error, setError] = useState("");

  const options = [
    {
      label: "Return",
      value: "Return",
    },
    {
      label: "Replenishment",
      value: "Replenishment",
    },
    {
      label: "Shipment",
      value: "Shipment",
    },
    {
      label: "Stock Take",
      value: "Stock Take"
    },
    { label: "Transfer",
      value: "Transfer"
    },
    { label: "Decrease",
      value: "Decrease"
    },
    { label: "Increase",
      value: "Increase"
    }
  ];

  const history = useHistory();

  useEffect(() => {
    fetch(`${URL}/warehouse`)
      .then((data) => data.json())
      .then((res) => setWarehouse(res))
      .catch((err) => console.log(err));

    setItems(null);
    setUuid(null);
  }, [setItems, setUuid]);

  useEffect(() => {
    localStorage.removeItem("oldItems");
  }, []);

  const loadOption = warehouse.map((i) => {
    return (
      <option key={i.uuid} value={i.uuid}>
        {i.name}
      </option>
    );
  });

  const searchItem = async (event) => {
    if (warehouseSelected !== "") {
      event.preventDefault();
      setError("");
      setInventoryItem(null);
      setisLoading(true);
      var wareId = '';
      warehouse.forEach((elm) => {
        if (elm.name === warehouseSelected) {
            wareId = elm.id;
        }
      });
      const stockTakeName = stockTake;
      const bin = keyword;
      const reason = movementType;
      if (bin !== "") {
        const result = await axios({
          method: "post",
          url: `${URL}/inventory-trace`,
          data: {
            sku: bin,
            warehouse: wareId
          },
        });
        setInventoryItem(await result.data);
        localStorage.setItem("oldItems", JSON.stringify(result.data));
      } else if (reason !== "") {
        const result = await axios({
          method: "post",
          url: `${URL}/inventory-tracer`,
          data: {
            sku: reason,
            warehouse: wareId,
            stock_take: stockTakeName
          },
        });
        setInventoryItem(await result.data);
        localStorage.setItem("oldItems", JSON.stringify(result.data));
      }


      setisLoading(false);
      setError("");
      console.log(JSON.parse(localStorage.getItem("oldItems")));
    } else {
      event.preventDefault();
      setError("Please Select a warehouse and Enter Bin by Location");
    }
  };

  const setItemsHandeler = async () => {
    if (warehouseSelected !== "" && inventoryItem !== null && keyword !== null) {
      setItems(inventoryItem);
      history.push("/scanner");
    } else {
      setError("Please Select a warehouse and Enter Bin by Location");
    }
  };

  const setWarehouseHand = (value) => {
    setWarehouseSelected(value);
    setUuid(value);
  };

  return (
    <>
      <PageHeader title="Stock Movements" />
      <div className="page__body">
        {error && <span className="alert alert-danger d-block">{error}</span>}
        <div className="shadow__box d-flex align-items-center">
          <select
            defaultValue="Select Warehouse"
            className="custom__form_input mr-2 w-50"
            onChange={(e) => setWarehouseHand(e.target.value)}
          >
            <option disabled selected>
              Select Warehouse
            </option>
            {loadOption}
          </select>
          <form onSubmit={searchItem} className="w-100">
            <div className="d-flex align-items-center">
              <input
                disabled={isLoading}
                type="text"
                className="custom__form_input mr-2 w-100"
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Enter Sku/Barcode"
                value={keyword}
              />
            <select
              defaultValue="Select Movement Type"
              className="custom__form_input mr-2 w-100"
              onChange={(e) => setMovementType(e.target.value)}
            >
              {options.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
            </select>
            <input
                disabled={isLoading}
                type="text"
                className="custom__form_input mr-2 w-100"
                onChange={(e) => setStockTake(e.target.value)}
                placeholder="Enter Stock Take"
                value={stockTake}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="button button__dark"
              >
                Search
              </button>
            </div>
          </form>
        </div>

        <div className="mt-5 text-center">{isLoading && <Spinner />}</div>
        {inventoryItem && (
          <>
            <div className="d-flex justify-content-between align-items-center my-4">
              <h5 className="m-0">Found {inventoryItem.length} Items</h5>
            </div>

            <div className="inventory_item">
              <table className="table my-3 bg-white">
                <thead>
                  <tr>
                    <th scope="col">SKU</th>
                    <th scope="col">Scanned Qty</th>
                    <th scope="col">Movement Type</th>
                    <th scope="col">Note</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>

                <tbody>
                  {inventoryItem?.map((k) => (
                    <tr key={k?.sku + k?.warehouse_uuid}>
                      <td>{k?.sku}</td>
                      <td>{k?.adjustment}</td>
                      <td>{k?.movement_type}</td>
                      <td>{k?.note}</td>
                      <td>{k?.transaction_date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapDispatch = (Dispatch) => {
  return {
    setItems: (fillIt) => Dispatch({ type: "SET_SCAN_ITEM", filItems: fillIt }),
    setUuid: (uuid) => Dispatch({ type: "SET_UUID", uuid: uuid }),
  };
};

export default connect(null, mapDispatch)(Inventory);
