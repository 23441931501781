// src/components/PasswordInput.js

import React from 'react';

function PasswordInput({ onPasswordSubmit }) {
  const handlePasswordSubmit = () => {
    const password = window.prompt('Enter the password:');
    if (password) {
      onPasswordSubmit(password);
    }
  };

  return (
    <div className="password-input">
      <h2>Enter Password to Access the Site</h2>
      <button onClick={handlePasswordSubmit}>Submit</button>
    </div>
  );
}

export default PasswordInput;