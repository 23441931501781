import React from "react";
import inputFields from "../assets/listingFields";

const ShoeInput = ({ fieldsOnChangeHandler }) => {
  const renderShoeForm = inputFields.accessoriesForm.map((el) => {
    if (el.select === false) {
      return (
        <div className="col-md-12 mb-3" key={el.name}>
          <label className="mr-3" htmlFor={el.name}>
            {el.label}
          </label>
          <input
            onChange={(e) => fieldsOnChangeHandler(e, el.isCustom)}
            name={el.name}
            type={el.type}
            placeholder={el.label}
            required={el.required}
            className="form-control"
          />
        </div>
      );
    } else {
      return (
        <div className="col-md-2 mb-3" key={el.name}>
          <label htmlFor={el.name}>{el.label}</label>
          <select
            onChange={(e) => fieldsOnChangeHandler(e, el.isCustom)}
            name={el.name}
            placeholder={el.label}
            required={el.required}
            className="form-control"
          >
            <option value="" selected disabled>
              Select {el.label}
            </option>
            {el.option.map((op) => (
              <option value={op} key={op}>
                {op}
              </option>
            ))}
          </select>
        </div>
      );
    }
  });

  return (
    <>
      <div className="row">{renderShoeForm}</div>
    </>
  );
};

export default ShoeInput;
